import { ComponentType, ReactElement, Suspense } from "react";
import { useAfterMount } from "src/hooks/useAfterMount";
export function withSuspense<T extends object = {}>(
  Component: ComponentType<T>,
  Fallback: React.FC<T> | string = "",
  options: { clientOnly?: boolean } = { clientOnly: false }
) {
  const ComponentWithSuspense = (componentProps: T) => {
    const isMounted = useAfterMount();
    const fallback =
      typeof Fallback === "string" ? (
        Fallback
      ) : (
        <Fallback {...componentProps} />
      );
    if (options.clientOnly && !isMounted) {
      return fallback as ReactElement;
    }
    return (
      <Suspense fallback={fallback}>
        <Component {...componentProps} />
      </Suspense>
    );
  };
  ComponentWithSuspense.displayName = `withSuspense(${
    Component.displayName || Component.name
  })`;
  return ComponentWithSuspense;
}
